<template>
  <div class="max-w-[400px] mx-auto px-10">
    <h3 class="scroll-m-20 text-2xl font-semibold tracking-tight py-5">
      <img :src="colorMode.value == 'dark' ? '/images/logos/superseeded_logo_white_small@0.5x.png' : '/images/logos/superseeded_logo_black_small@0.5x.png'" alt="SuperSeeded" class="h-20" />
    </h3>
    <AccountLoginForm/>
    <nuxt-link to="/register" class="flex justify-center items-center">
      <Button variant="ghost" class="whitespace-nowrap">
        Don't have an account?
        <Icon name="material-symbols:arrow-right-alt-rounded" size="20" class="mx-3" />
        <span class="underline">Register now</span>
      </Button>
      
    </nuxt-link>
  </div>
</template>

<script setup lang="ts" >
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-vue-next'
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { onKeyStroke } from '@vueuse/core'  
import { useFocus } from '@vueuse/core'
import GetNotified from '@/components/GetNotified.vue'

const username = ref()
const { focused } = useFocus(username, { initialValue: true })

const uiStore = useUiStore()
const { toggleBottomSheet, setBottomDrawerConfig } = uiStore
const bottomDrawer = useBottomDrawer()

const colorMode = useColorMode()

const client = useSupabaseClient()
const user = useSupabaseUser()
const loading = ref(false)
const authError = ref('')
const usingEmailPassword = ref(false)
const showPassword = ref(false)

const formSchema = toTypedSchema(z.object({
    email: z.string().email().optional(),
    password: z.string().min(8).optional(),
}).transform((o) => ({ 
    email: o.email,
    password: o.password 
})))

const apiErrors = ref({
  wrongCredentials: false,
  Provider: false,
  accountNotVerified: false,
  accountSuspended: false,
  accessDenied: false,
  networkError: false
})

const apiErrorMessages = computed(() => {
  return {
    email: apiErrors.value.wrongCredentials ? 'Incorrect username or password' : 
           apiErrors.value.accountSuspended ? 'Account suspended' : 
           apiErrors.value.accessDenied ? 'Access not granted' : 
           apiErrors.value.networkError ? 'Network error occurred' : '',
    password: apiErrors.value.accountNotVerified ? 'Account not verified' : ''
  };
});

const { validate, errors, resetForm, handleSubmit, setErrors, meta: formMeta } = useForm({
  validationSchema: formSchema
})

const route = useRoute()

const showGetNotifiedDrawer = () => {
  bottomDrawer.open({
    hideCloseButton: true,
    component: markRaw(GetNotified),
    props: {
      email: String(route.query?.email ?? '')
    }
  })
}

onMounted(() => {
  if (route.query.early_access) {  
    showGetNotifiedDrawer()
  }
})

watchEffect(async () => {
  if (user.value && !route.query.early_access) {
    const hasAccess = await checkAccess()
    if (hasAccess) {
      await navigateTo('/confirm')
    }
  }
});

defineOgImageComponent('NuxtSeo', {
    title: 'SuperSeeded.ai',
    description: '',
    theme: '#c8c9d0',
    colorMode: 'light',
})

definePageMeta({
    layout: "auth",
})

interface ConfirmResponse {
  status: number;
  data?: {
    allowed: boolean;
  };
  error?: string;
}

const checkAccess = async () => {
  try {
    const { data: responseData, error } = await useFetch<ConfirmResponse>('/api/users/confirm', { 
      method: 'POST',
      retry: 1
    })

    if (error.value) {
      console.error('Error checking access:', error.value);
      apiErrors.value.networkError = true;
      return false;
    }

    const data = responseData.value
    if (!data) {
      console.error('No data received from access check');
      apiErrors.value.networkError = true;
      return false;
    }

    if (data.error) {
      console.error('API error:', data.error);
      apiErrors.value.accessDenied = true;
      return false;
    }

    if (!data.data?.allowed) {
      apiErrors.value.accessDenied = true;
      showGetNotifiedDrawer()
      return false;
    }

    return true;
  } catch (error) {
    console.error('Unexpected error checking access:', error);
    apiErrors.value.networkError = true;
    return false;
  }
}
</script>
